import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import "../Assets/css/base.css";
import "../Assets/css/demo1.css";
import "../Assets/css/home.css";
import SEO from "../components/seo";
import BodyClassName from "react-body-classname";
import Header from "../components/header";

class DesignCode extends React.Component {
  componentDidMount() {
    const charming = require("charming");
    document.documentElement.className = "js";
    var supportsCssVars = function() {
      var e,
        t = document.createElement("style");
      return (
        (t.innerHTML = "root: { --tmp-var: bold; }"),
        document.head.appendChild(t),
        (e = !!(
          window.CSS &&
          window.CSS.supports &&
          window.CSS.supports("font-weight", "var(--tmp-var)")
        )),
        t.parentNode.removeChild(t),
        e
      );
    };
    supportsCssVars() ||
      alert(
        "Please view this demo in a modern browser that supports CSS Variables."
      );

    {
      const chars = ["$", "%", "#", "@", "&", "(", ")", "=", "*", "/"];
      const charsTotal = chars.length;
      const getRandomInt = (min, max) =>
        Math.floor(Math.random() * (max - min + 1)) + min;

      class Entry {
        constructor(el) {
          this.DOM = { el: el };
          this.DOM.image = this.DOM.el.querySelector(".content__img");
          this.DOM.title = {
            word: this.DOM.el.querySelector(".content__text")
          };
          charming(this.DOM.title.word);
          this.DOM.title.letters = Array.from(
            this.DOM.title.word.querySelectorAll("span")
          );
          this.DOM.title.letters.forEach(
            letter => (letter.dataset.initial = letter.innerHTML)
          );
          this.lettersTotal = this.DOM.title.letters.length;
          observer.observe(this.DOM.el);
        }
        enter(direction = "down") {
          this.DOM.title.word.style.opacity = 1;
          this.timeouts = [];
          this.complete = false;
          let cnt = 0;
          this.DOM.title.letters.forEach((letter, pos) => {
            const timeout = setTimeout(() => {
              letter.innerHTML = chars[getRandomInt(0, charsTotal - 1)];
              setTimeout(() => {
                letter.innerHTML = letter.dataset.initial;
                ++cnt;
                if (cnt === this.lettersTotal) {
                  this.complete = true;
                }
              }, 100);
            }, pos * 80);
            this.timeouts.push(timeout);
          });
        }

        exit(direction = "down") {
          this.DOM.title.word.style.opacity = 0;
          if (this.complete) return;
          for (let i = 0, len = this.timeouts.length; i <= len - 1; ++i) {
            clearTimeout(this.timeouts[i]);
          }
        }
      }

      let observer;
      let current = -1;
      let allentries = [];
      const sections = Array.from(
        document.querySelectorAll(".section__content")
      );
      // Preload all the images in the page..
      document.body.classList.remove("loading");
      if ("IntersectionObserver" in window) {
        document.body.classList.add("ioapi", "demo-1");

        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.intersectionRatio > 0.5) {
                const newcurrent = sections.indexOf(entry.target);
                if (newcurrent === current) return;
                const direction = newcurrent > current;
                if (current >= 0) {
                  allentries[current].exit(direction ? "down" : "up");
                }
                allentries[newcurrent].enter(direction ? "down" : "up");
                current = newcurrent;
              }
            });
          },
          { threshold: 0.5 }
        );

        sections.forEach(section => allentries.push(new Entry(section)));
      }
    }
  }

  render() {
    return (
      <BodyClassName className="demo-1 loading">
        <main>
          <SEO
            title="Design and Code · Apps/Websites Design and Development"
            description="At Makook we design and code the best tools to help your brand redefine the
             rules of how we live our lives.  To the clients who dare to be different, We develop the creative
              elements that communicate your meaningful difference to the world, resulting in well curated 
              solutions that put focus on the best user experiences and user 
            interfaces, we do Web development, App development, UX/UI design, Brand Identities, Design Systems and Interaction Design"
            image={
              "https://makook.space" +
              require("../images/makook-designcode.jpg")
            }
          />
          <Header />
          <div className="content">
            <div className="section__content">
              <div className="content__main">
                <span>From design to code, Everything is made to be Art.</span>
              </div>
              <h2 className="content__text">DesignCode↓</h2>
            </div>

            <div className="section__content">
              <Link className="content__link" to="/designcode/Octrino">
                <Img
                  className="content__img"
                  fluid={this.props.data.imageOne.childImageSharp.fluid}
                />
              </Link>
              <h2 className="content__text">Octrino</h2>
            </div>
            <div className="section__content">
              <Link className="content__link" to="/designcode/dirha">
                <Img
                  className="content__img"
                  fluid={this.props.data.imageTwo.childImageSharp.fluid}
                />
              </Link>
              <h2 className="content__text">Dirha App</h2>
            </div>

            <div className="section__content">
              <h2 className="content__text">Contact</h2>
              <div className="content__contact">
                <p className="reach-out">
                  Give us a call, or reach out to us via social media. We’re
                  always open for a chat.
                </p>
                <div className="content__info">
                  <a
                    className="contact--link--footer"
                    href="mailto:hello@makook.space"
                  >
                    hello@makook.space
                  </a>
                  <br></br>
                  <a
                    className="contact--link--footer"
                    href="tel:+213 550 246249"
                  >
                    +213 (550) 246249
                  </a>
                </div>
                <div>
                  <a
                    className="contact--link--footer"
                    href="https://www.facebook.com/makook.space"
                  >
                    Facebook
                  </a>
                  <a
                    className="contact--link--footer"
                    href="http://instagram.com/makook.space"
                  >
                    instagram
                  </a>
                  <a
                    className="contact--link--footer"
                    href="https://twitter.com/makookstudio"
                  >
                    Twitter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </main>
      </BodyClassName>
    );
  }
}

export default DesignCode;

export const DesignCodeQuery = graphql`
  fragment DesignCodeQuery on File {
    childImageSharp {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const CodeDesign = graphql`
  query {
    imageOne: file(
      relativePath: { eq: "Octrino/otrino-My-projects-screen.jpg" }
    ) {
      ...fluidImage
    }

    imageTwo: file(relativePath: { eq: "Dirha/Dirha-3-screens.jpg" }) {
      ...fluidImage
    }
  }
`;
